import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"
import { trigger } from "../../utils/events"

// Connects to data-controller="canopy--dialog-component"
export default class extends Controller {
  static targets = ["dialog"]

  static values = {
    closeOnFormSubmit: Boolean,
  }

  closeOnFormSubmitValue: boolean
  dialogTarget: HTMLDialogElement

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }

  onDrawerOpenFunction: (event) => void

  connect() {
    if (!this.isPreview) {
      this.open()
    }

    document.addEventListener("turbo:submit-end", this.handleSubmit)
    this.element.addEventListener("click", this.closeBackdrop)
    document.addEventListener("keyup", this.handleKeyup)
  }

  disconnect() {
    document.removeEventListener("turbo:submit-end", this.handleSubmit)
    this.element.removeEventListener("click", this.closeBackdrop)
    document.removeEventListener("keyup", this.handleKeyup)
  }

  open() {
    this.dialogTarget.showModal()
    if (this.dialogTarget.classList.contains("drawer")) {
      this.dialogTarget.classList.remove("translate-x-full")
    } else {
      this.dialogTarget.classList.remove("translate-y-full")
    }
  }

  close() {
    // Listened to in nonlinear_task_form_controller.ts
    trigger("drawer:close", {})
    if (this.dialogTarget.classList.contains("drawer")) {
      this.dialogTarget.classList.add("translate-x-full")
    } else {
      this.dialogTarget.classList.add("translate-y-full")
    }
    setTimeout(() => {
      this.dialogTarget.close()
      this.element.remove()
    }, 200)
  }

  handleSubmit = (e: CustomEvent) => {
    if (this.closeOnFormSubmitValue && e.detail.success) {
      this.close()
    }
  }

  // Closes modal when clicking on backdrop
  closeBackdrop = (e: MouseEvent) => {
    const target = e.target as HTMLElement
    if (target.nodeName === "DIALOG") {
      this.close()
    }
  }

  // New method to handle keyup events
  handleKeyup = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      this.close()
    }
  }
}
