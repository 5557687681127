import React, { createContext, useContext, useState, useEffect } from "react"
import { getVisualBuilderCardDimensions } from "../../../../utils"

const WorkflowContext = createContext({})

const WorkflowContextProvider = ({ ...props }) => {
  const [selectedTaskId, setSelectedTaskId] = useState(props.selectedTaskId)
  const [selectedTaskRequestStage, setSelectedTaskRequestStage] = useState(props.selectedTaskRequestStage)
  const [editable, setEditable] = useState(props.editable)
  const [isSubworkflow, setIsSubworkflow] = useState(props.isSubworkflow)
  const cardDimensions = getVisualBuilderCardDimensions(props.isWorkflow)
  const [drawerOpened, setDrawerOpened] = useState(Boolean(document.querySelector("#drawer #modal-body")))
  const [workflowSimplificationWorkflowEditing, _] = useState(props.workflowSimplificationWorkflowEditing)

  useEffect(() => {
    const documentHandler = (e) => {
      if (!drawerOpened && selectedTaskId !== undefined) setSelectedTaskId(undefined)
    }
    document.addEventListener("click", documentHandler)
    return () => {
      document.removeEventListener("click", documentHandler)
    }
  }, [selectedTaskId])

  return (
    <WorkflowContext.Provider
      value={{
        selectedTaskId,
        setSelectedTaskId,
        selectedTaskRequestStage,
        setSelectedTaskRequestStage,
        editable,
        isSubworkflow,
        setEditable,
        cardDimensions,
        drawerOpened,
        setDrawerOpened,
        workflowSimplificationWorkflowEditing,
      }}
      {...props}
    />
  )
}

export const useWorkflow = () => {
  const context = useContext(WorkflowContext)

  if (!context) {
    throw new Error("The useWorkflow hook must be use inside a WorkflowContextProvider")
  }

  return context
}

export default WorkflowContextProvider
