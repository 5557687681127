import { Handle, Position, useNodes } from "reactflow"
import React from "react"
import { useWorkflow } from "./workflowContext"
import { getVisualBuilderHandleStyles } from "../../../../utils"
import { AddFirstTaskButton } from "./addFirstTaskButton"

const noTasks = (nodes) => nodes.filter((node) => node.type === "workflowTaskCard").length === 0

export const EditIntakeFormCardNode = ({ data }) => {
  const nodes = useNodes()

  const { cardDimensions, editable } = useWorkflow()
  const containerStyles = {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    width: `${cardDimensions.width}px`,
    height: `300px`,
  }
  const handleStyles = getVisualBuilderHandleStyles("right")

  return (
    <div
      id={`workflow-task-card-${data.taskId}`}
      className="pointer-events-auto z-2 p-4 border border-gray-300 rounded-md bg-white"
      style={containerStyles}
    >
      <div className="flex justify-center items-center w-full bg-gray-50 shadow-inner mb-1 rounded-md">
        <img src="/assets/workflows/intake_form.png" alt="Intake Form" className={"mt-5"} />
      </div>
      <p className="body-text body-text--long font-bold mt-2">{data.requestCategory?.name} Intake Form</p>
      <a
        className="pointer-events-auto z-10 block mt-2 rounded-md text-center space-y-1 bg-purple-500 py-2 px-3"
        href={data.editIntakeFormPath}
        target="_blank"
      >
        <span className="font-semibold text-base text-white">Edit Intake Form</span>
      </a>

      <Handle type="source" position={Position.Right} style={handleStyles} />
      {editable && data.addTaskPathRight && noTasks(nodes) && <AddFirstTaskButton actionPath={data.addTaskPathRight} />}
    </div>
  )
}
